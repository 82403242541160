<template>
  <div class="goods" id="goods" name="goods">
    <div class="wrapper main">
      <div class="left">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="bread">
          <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item>Products</el-breadcrumb-item>
        </el-breadcrumb> -->
        <div class="category-title">{{ $t('list.category') }}</div>
        <div class="leftbox">
          <div class="title">{{ $t('list.ordertype') }}</div>
          <el-radio-group v-model="checkedOrderType">
            <el-radio :label="0"  style="margin-bottom: 10px;" @click.native.prevent="cancel(0)">In Stock</el-radio><br>
            <el-radio :label="1" style="margin-bottom: 10px;" @click.native.prevent="cancel(1)">Pre-Order</el-radio><br>
            <el-radio :label="2" @click.native.prevent="cancel(2)">Group Buying</el-radio><br>
          </el-radio-group>
        </div>

        <div class="leftbox">
          <div class="title">{{ $t('list.brands') }}</div>
          <el-checkbox-group v-if="this.$store.getters.getUser" v-model="brandCheckedList" @change="getGoodsList">
            <el-checkbox v-for="(brand, index) in brands" :key="index" class="brand-item"
              :label="brand.brand"></el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-else v-model="brandCheckedList" @change="getGoodsList">
            <el-checkbox v-for="(brands1, index) in brands1" :key="index" class="brand-item"
              :label="brands1.brand"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="leftbox">
          <el-checkbox-group v-model="categoryCheckedList" @change="getGoodsList">
            <div v-for="category in categories" :key="category.id" style="margin-bottom: 10px;">
              <div style="font-size: 15px; font-weight: bolder; margin-bottom: 10px;">{{ category.categoryName }}</div>
              <el-checkbox v-for="subCategory in category.children" :key="subCategory.id" class="category-item"
                :label="subCategory.id">{{ subCategory.categoryName }}</el-checkbox>
            </div>
          </el-checkbox-group>
        </div>

        <div class="leftbox">
          <div class="title">Price Ranage</div>
          <span> $ </span>
          <el-input v-model="minAmount" class="amount-input" size="mini" placeholder="1"></el-input>
          <span> - </span>
          <el-input v-model="maxAmount" class="amount-input" size="mini" placeholder="1000"></el-input>
        </div>
      </div>
      <div class="right">
        <el-row style="margin-bottom: 20px;margin-left: 10px;">
          <el-col :span="3" v-if="$store.getters.getUser">
            <div>
              <router-link to="/quick">
                <img src="../assets/imgs/uploads/product-left.png" style="width: 37px;height: 37px;" alt="">
              </router-link>
              <img src="../assets/imgs/uploads/product-right.png" style="width: 37px;height: 37px;" alt="">
            </div>
          </el-col>
          <el-col :span="9">
            <el-select v-model="sortType" placeholder="Sort by...">
              <el-option v-for="item in sortList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3" v-if="!$store.getters.getUser">
            <div style="visibility: hidden;">1</div>
          </el-col>
          <el-col :span="8" :offset="4" style="padding-right: 6px;">
            <el-input placeholder="Search..." v-model="keyword" suffix-icon="el-icon-search"
              @keyup.enter.native="handleSearch">
            </el-input>
          </el-col>
        </el-row>

        <div>
          <el-row v-if="loading" style="text-align: center">
            <el-col :span="24"><img src="../assets/imgs/uploads/Animation.gif" alt=""></el-col>
          </el-row>
          <el-row v-else-if="goods.length > 0" style="text-align: center">
            <div style="display: flex;justify-content: start;color: #333;margin: 0 0 20px 20px;">Display
              <span style="font-weight: bold;margin: 0 5px;">{{ total > 16 ? 16 : total }}/{{ total }}</span>results
            </div>
            <el-col :span="24">
              <MyList :list="goods"></MyList>
            </el-col>
            <el-col :span="24">
              <el-pagination layout="prev, pager, next,jumper" :page-size="pageSize" :total="total" :pager-count="5"
                background @current-change="currentChange" :current-page="currentPage"></el-pagination>
            </el-col>
          </el-row>
          <el-row v-else style="text-align: center">
            <el-col :span="24" style="font-size: 40px;font-weight: bold;color: #959595;">Sorry...</el-col>
            <el-col :span="24" style="font-size: 40px;font-weight: bold;color: #959595;">No relevant products were
              found</el-col>
            <el-col :span="24" style="font-size: 18px;color: #959595;">Please look at other
              products.</el-col>
            <el-col :span="24"><img style="width: 339px; height: 350px;" src="../assets/imgs/cart-empty.png"
                alt=""></el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    meta: [
      {
        name: 'description',
        content: 'Explore our extensive selection of cat and dog toys at our online pet toy store. Find a wide range of toys to entertain and enrich the lives of your pets.Browse through our curated collection of cat and dog toys on our website. Discover toys designed to stimulate your pets natural instincts and provide hours of fun.Shop for high-quality cat and dog toys online. Choose from a variety of toys, including plush toys, interactive toys, and treat dispensers, to keep your pets happy and engaged.Elevate your pets playtime with our diverse range of toys for cats and dogs. From chew toys to puzzle toys, we have everything you need to enhance your pets wellbeing.Treat your furry friends to the best toys available online. Explore our selection of innovative and durable cat and dog toys crafted for both entertainment and enrichment.'
      },
      {
        name: 'keywords',
        content: 'Cat and dog toys,Pet toys online,Online pet toy store,Interactive pet toys,Plush pet toys,Chew toys for pets,Puzzle toys for cats and dogs,Treat dispensing toys,Enrichment toys for pets,High-quality pet toys'
      }
    ]
  },
  data() {
    return {
      categoryList: [], //分类列表
      categoryID: [], // 分类id
      product: "", // 商品列表
      productList: "",
      total: 0, // 商品总量
      pageSize: 16, // 每页显示的商品数量
      currentPage: 1, //当前页码
      activeName: "-1", // 分类列表当前选中的id
      search: "", // 搜索条件
      brands: [],
      brands1: [
        { brand: 'Gifts For Paws' },
        { brand: 'Gigwi' },
        { brand: 'PETGEEK' },
        { brand: 'SKIPDAWG' }
      ],
      selectedIndex: null,
      categories: '',
      messages: [],
      messages1: [],
      clicks: '',
      highlightedIndex: null, // 选中的文字索引,
      checkedOrderType: 0,
      brandCheckedList: [],
      brandCheckedList1: [],
      categoryCheckedList: [],
      minAmount: '',
      maxAmount: '',
      sortList: [
        { value: 'Best Sellers', label: 'Best Sellers' },
        { value: 'Newest Arrivals', label: 'Newest Arrivals' },
        { value: 'Price Low to High', label: 'Price Low to High' },
        { value: 'Price High to Low', label: 'Price High to Low' },
        { value: 'Default', label: 'Default' },
      ],
      sortType: '',
      keyword: '',
      goods: [],
      loading: true,
    };
  },
  computed: {
    summaryCategories: function () {
      let arr = []
      this.categories.foreach(item => {
        item.children.foreach(item => {
          arr.push(item)
        })
      });
      return arr;
    }
  },
  mounted() {
    this.getfenlei();
  },
  activated() {
    this.getBrands()
  },
  watch: {

  },
  methods: {
    handleSearch() {
      this.getGoodsList()
    },
    getBrands() {
      this.$axios
        .post("/api/mall/member/getUserBrands", {
          id: this.$store.getters.getUser.id,
        })
        .then(res => {
          this.brands = res.data.data
          this.brandCheckedList1 = this.brands.map(item => item.brand)
          this.getGoodsList()
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    getGoodsList() {
      this.brandCheckedList1 = this.brandCheckedList
      if (!this.$store.getters.getUser) {
        this.$axios.post("/api/mall/spu/getPublicPage", {
          pageNum: this.keyword === '' ? this.currentPage : 1,
          pageSize: this.pageSize,
          orderType: this.checkedOrderType,
          brands: this.brandCheckedList1,
          categoryIds: this.categoryCheckedList,
          sortType: this.sortType,
          keyword: this.keyword
        })
          .then(res => {
            this.goods = res.data.data.records;
            this.total = res.data.data.total;
            this.loading = false;
            window.scrollTo(0, 0);
          }).catch(err => {
            return Promise.reject(err);
          });
      } else {
        this.$axios.post("/api/mall/spu/page", {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          orderType: this.checkedOrderType,
          brands: this.brandCheckedList1,
          categoryIds: this.categoryCheckedList,
          sortType: this.sortType,
          keyword: this.keyword
        })
          .then(res => {
            this.goods = res.data.data.records;
            this.total = res.data.data.total;
            this.loading = false;
            window.scrollTo(0, 0);
          }).catch(err => {
            return Promise.reject(err);
          });
      }

    },
    getTypeData(val) {
      this.$axios
        .post("/api/mall/sku/page", {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          proType: val
        })
        .then(res => {
          this.product = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(err => {
          return Promise.reject(err);
        });
      this.highlightedIndex = val
    },
    // 返回顶部
    backtop() {
      const timer = setInterval(function () {
        const top = document.documentElement.scrollTop || document.body.scrollTop;
        const speed = Math.floor(-top / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          top + speed;
        if (top === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    // 页码变化调用currentChange方法
    currentChange(currentPage) {
      this.currentPage = currentPage;
      this.getGoodsList()
    },
    // 向后端请求分类列表数据
    getCategory() {
      this.$axios
        .post("/api/product/getCategory", {})
        .then(res => {
          const val = {
            category_id: 0,
            category_name: "全部"
          };
          const cate = res.data.category;
          cate.unshift(val);
          this.categoryList = cate;
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    getfenlei() {
      this.$axios
        .post("/api/mall/category/all")
        .then(res => {
          this.categories = res.data.data
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    // 向后端请求全部商品或分类商品数据    
    getData() {
      this.$axios
        .post("/api/mall/sku/page", {
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
        .then(res => {
          this.product = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    // 通过搜索条件向后端请求商品数据
    getProductBySearch() {
      this.$axios
        .post("/api/mall/sku/page", {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          name: this.search
        })
        .then(res => {
          this.product = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    selectbrands(index) {
      this.selectedIndex = index === this.selectedIndex ? null : index;
    },
    cancel(e) {
      if (this.checkedOrderType === e) {
        this.checkedOrderType = null
      } else {
        this.checkedOrderType = e
      }
      this.getGoodsList()
    },
  }
};
</script>

<style scoped>
/* 产品目录的样式表 */
.wrapper {
  width: 1280px;
  margin: 0 auto 64px;
}

.main {
  position: relative;
  margin-top: 30px;
  display: flex;
}

::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #000;
}

.bread {
  cursor: pointer;
  position: absolute;
  top: -48px;
  left: 0%;
}

.left {
  flex: 1;
}

.leftbox {
  margin-bottom: 20px;
}

.leftbox p {
  cursor: pointer;
}

.active {
  box-shadow: none !important;
  /* 去除阴影效果 */
  border: 3px solid #ccc;
  border-radius: 5px;
}


.right {
  flex: 4;
  margin-left: 50px;
}

/*不带背景的分页按钮背景色begin*/
::v-deep .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  -webkit-box-shadow: 0 0 0px 0px transparent;
  box-shadow: 0 0 0px 0px transparent;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333;
  border-color: #333;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #333;
  background: #333;
}

::v-deep .el-button--primary {
  background-color: #959595;
  border-color: #959595;
}

::v-deep .el-radio__label {
  font-size: 14px;
}

::v-deep .el-checkbox__label {
  font-size: 14px;
}

::v-deep .el-radio__input.is-checked+.el-radio__label {
  color: #333;
  font-weight: bold;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #333;
  font-weight: bold;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-color: #333;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #CA141D;
}

::v-deep .el-input__inner:focus {
  border-color: #333;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #000;
  color: #FFF;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* 主要内容区CSS END */

.category-title {
  font-size: 16px;
  margin-bottom: 12px;
}

.title {
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 11px;
}

.brand-item,
.category-item {
  display: block;
}

.amount-input {
  width: 100px;
}
</style>